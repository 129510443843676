import React, { useEffect, useMemo, useState } from "react";
import "../Styles/subcat.css";
import {
  Alert,
  Autocomplete,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  MenuItem,
  Modal,
  Select,
  Snackbar,
  TextField,
  Typography,
  useTheme,
  InputLabel,
} from "@mui/material";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import moment, { normalizeUnits } from "moment/moment";
import Dialog from "@mui/material/Dialog";

import { Stack } from "@mui/system";
import Skeleton from "@mui/material/Skeleton";
import { ADD, DELETE, GET, UPDATE, UPLOAD } from "../Functions/apiFunction";
import api from "../Data/api";

import { tokens } from "../theme";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "90vw", sm: 600, md: 700, lg: 700, xl: 700 },
  maxHeight: "90vh",
  overflow: "scroll",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "8px",
  p: 2,
};

function Offers() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [pageSize, setpageSize] = useState(20);
  const [open, setOpen] = useState(false);
  const [isAddModel, setisAddModel] = useState(false);
  const [dailogOpne, setdailogOpne] = useState(false);
  const [snakbarOpen, setsnakbarOpen] = useState(false);
  const [alertType, setalertType] = useState("");
  const [alertMsg, setalertMsg] = useState("");
  const [reFetch, setreFetch] = useState(false);

  const [discType, setDiscType] = useState();
  const [offers, setOffers] = useState();
  const [type, setType] = useState();
  const [discValue, setDiscValue] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [minThreshold, setMinThreshold] = useState("");
  const [maxThreshold, setMaxThreshold] = useState("");

  const [isUpdating, setisUpdating] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleDailogOpen = () => setdailogOpne(true);
  const handleDailogClose = () => setdailogOpne(false);
  const handleSnakBarOpen = () => setsnakbarOpen(true);
  const handleSnakBarClose = () => setsnakbarOpen(false);

  const DISCOUNT_TYPE = [
    { value: "PERCENT", name: "PERCENT" },
    { value: "AMOUNT", name: "AMOUNT" },
  ];
  const OFFER_TYPE = [
    { value: "QTY", name: "QTY" },
    { value: "QTY", name: "PERIOD" },
  ];

  const admin = JSON.parse(sessionStorage.getItem("admin"));
  const token = `Bearer ${admin.token}`;

  // update user state
  const [code, setCode] = useState("");
  const [id, setId] = useState("");
  const handleDiscTypeChange = (event, newValue) => {
    setDiscType(newValue ? newValue.value : ""); // Storing the name of the selected option
  };

  const handleOfferTypeChange = (event, newValue) => {
    setType(newValue ? newValue.value : ""); // Storing the name of the selected option
  };

  useEffect(() => {
    // get subcat

    const getOffers = async () => {
      const url = `${api}/get_offers`;
      const offers = await GET(token, url);

      setOffers(offers.data);
      setisUpdating(false);
      handleSnakBarOpen();

      setisUpdating(false);
      setalertType("success");
      setalertMsg("New offer added successfully");

      handleClose();
    };
    getOffers();
  }, [reFetch, admin.token, token]);

  // add category
  const AddOffer = async (e) => {
    e.preventDefault();
    const data = JSON.stringify({
      code: code,
      disc_type: discType,
      type: type,
      disc_value: discValue,
      start_date: startDate,
      end_date: endDate,
      min_threshold: minThreshold,
      max_threshold: maxThreshold,
    });
    const url = `${api}/add_offer`;

    setisUpdating(true);
    const addOffer = await ADD(token, url, data);
    if (addOffer.response === 200) {
      setisUpdating(false);
      handleSnakBarOpen();
      setreFetch(!reFetch);
      setisUpdating(false);
      setalertType("success");
      setalertMsg("New offer added successfully");
    } else {
      setisUpdating(false);
      handleSnakBarOpen();
      setisUpdating(false);
      setalertType("error");
      setalertMsg("Failed adding new Offer");
    }
  };

  // Update Product

  const update = async (e) => {
    e.preventDefault();
    var updateData = JSON.stringify({
      id: id,
      end_date: endDate,
    });
    const url = `${api}/update_offer`;
    setisUpdating(true);
    const update = await UPDATE(token, url, updateData);

    if (update.response === 200) {
      setisUpdating(false);
      handleClose();
      handleSnakBarOpen();
      setreFetch(!reFetch);
      setalertType("success");
      setalertMsg("Updated successfully");
    } else {
      setisUpdating(false);
      handleSnakBarOpen();
      setalertType("error");
      setalertMsg("Something went Wrong! Please Try Again");
    }
  };

  const column = useMemo(
    () => [
      { field: "id", headerName: "id", width: 40 },
      { field: "code", headerName: "Code", width: 120 },
      {
        field: "type",
        headerName: "Type",
        width: 100,
       
      },
      { field: "disc_type", headerName: "Discount Type", width: 120},
      { field: "disc_value", headerName: "Discount Value", width: 120 },
      {
        field: "min_threshold",
        headerName: "Min Criteria",
        width: 100,
      },
      {
        field: "max_threshold",
        headerName: "Max Criteria",
        width: 100,
      },
      {
        field: "start_date",
        headerName: "Starts",
        width: 120,
        renderCell: (params) =>
          moment(params.row.start_date).format("DD-MM-YYYY"),
      },
      {
        field: "end_date",
        headerName: "Ends",
        width: 120,
        renderCell: (params) =>
          moment(params.row.end_date).format("DD-MM-YYYY"),
      },
      {
        field: "Update",
        headerName: "Update",
        width: 100,
        renderCell: (params) => (
          <button
            class="updateBtn"
            onClick={() => {
              debugger;
              setisAddModel(false);
              setCode(params.row.code);
              setType(params.row.type);
              setId(params.row.id);
              setDiscType(params.row.disc_type);
              setDiscValue(params.row.disc_value);
              setMinThreshold(params.row.min_threshold);
              setMaxThreshold(params.row.max_threshold);
              setStartDate(params.row.start_date);
              setEndDate(params.row.start_date);
              handleOpen();
            }}
          >
            <span class="icon">
              <i class="fa-regular fa-pen-to-square"></i>
            </span>
          </button>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // custom toolbar
  function CustomToolbar() {
    return (
      <GridToolbarContainer
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "20px",
          marginBottom: "20px",
        }}
      >
        <div>
          {" "}
          <GridToolbarExport color="secondary" sx={{ fontSize: "14px" }} />
          <Select
            sx={{
              width: "100px",
              height: "30px",
              marginLeft: "20px",
            }}
            color="primary"
            size="small"
            labelId="demo-select-small"
            id="demo-select-small"
            value={pageSize}
            label="Page Size"
            onChange={(e) => {
              setpageSize(e.target.value);
            }}
            className="TopPageBar"
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
        </div>

        <button
          class="cssbuttons-io-button"
          onClick={() => {
            setCode(code);
            setisAddModel(true);
            handleOpen();
          }}
        >
          {" "}
          Add New
          <div class="icon">
            <i class="fa-regular fa-plus"></i>
          </div>
        </button>
      </GridToolbarContainer>
    );
  }

  return (
    <div>
      <Box sx={{ height: 600, width: "100%" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "row-reverse",
          }}
        >
          {" "}
          <Typography variant="h3" component={"h3"} sx={{ textAlign: "left" }}>
            Manage Offers
          </Typography>
          <TextField
            margin="normal"
            size="small"
            sx={{ width: { xs: "80%", sm: "300px", md: "500px" } }}
            id="Search"
            label="Search"
            name="Search"
            color="secondary"
            onChange={(e) => {
              e.preventDefault();
              setTimeout(() => {
                function searchArrayByValue(arr, searchQuery) {
                  return arr.filter((obj) => {
                    return Object.values(obj).some((val) => {
                      if (typeof val === "string") {
                        return val
                          .toLowerCase()
                          .includes(searchQuery.toLowerCase());
                      }
                      return false;
                    });
                  });
                }
                setDiscType(
                  searchArrayByValue(code, e.target.value.toLowerCase())
                );
              }, 500);
            }}
          />
        </div>

        {offers ? (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              paddingBottom: "30px",
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-row": {
                fontSize: "14px",
              },
              "& .name-column--cell": {
                color: colors.greenAccent[300],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.navbarBG[400],
                borderBottom: "none",
                color: "#f5f5f5",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
                borderBottom: "#000",
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.navbarBG[400],
                color: "#f5f5f5 !important",
              },
              "& .MuiTablePagination-root": {
                color: "#f5f5f5 !important",
              },
              "& .MuiTablePagination-selectIcon": {
                color: "#f5f5f5 !important",
              },
              "& .MuiTablePagination-actions botton": {
                color: "#f5f5f5 !important",
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
            }}
          >
            {" "}
            <DataGrid
              sx={{ fontSize: "13px" }}
              columns={column}
              rows={offers}
              components={{ Toolbar: CustomToolbar }}
              rowsPerPageOptions={[10, 20, 25, 50, 100]}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setpageSize(newPageSize)}
            />
          </Box>
        ) : (
          <Stack spacing={1}>
            {/* For variant="text", adjust the height via font-size */}
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
            {/* For other variants, adjust the size with `width` and `height` */}

            <Skeleton
              variant="rectangular"
              animation="wave"
              width={"100%"}
              height={30}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              width={"100%"}
              height={30}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              width={"100%"}
              height={30}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              width={"100%"}
              height={30}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              width={"100%"}
              height={30}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              width={"100%"}
              height={30}
            />
          </Stack>
        )}
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {isAddModel ? "Add New Offer" : "Update Offer"}
          </Typography>
          {isAddModel ? (
            <Box component="form" sx={{ mt: 1 }} onSubmit={AddOffer}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="code"
                label="Code"
                name="code"
                autoComplete="text"
                autoFocus
                size="small"
                onChange={(e) => {
                  setCode(e.target.value);
                }}
                color="secondary"
              />

              <Autocomplete
                margin="normal"
                disablePortal
                fullWidth
                sx={{ width: "100%", mb: 2 }} // Added margin bottom
                id="combo-box-demo"
                options={OFFER_TYPE}
                onChange={handleOfferTypeChange}
                getOptionLabel={(option) => option.name} // Using the name property of each object as the option label
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Offer Type"
                    size="small"
                    fullWidth
                    required
                    color="secondary"
                  />
                )}
              />
              <Autocomplete
                margin="normal"
                disablePortal
                fullWidth
                sx={{ width: "100%", mb: 2 }} // Added margin bottom
                id="combo-box-demo"
                options={DISCOUNT_TYPE}
                onChange={handleDiscTypeChange}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Discount Type"
                    size="small"
                    fullWidth
                    required
                    color="secondary"
                  />
                )}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="disc_value"
                label="Discount Value"
                name="disc_value"
                autoComplete="text"
                size="small"
                onChange={(e) => {
                  setDiscValue(e.target.value);
                }}
                color="secondary"
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="start_date"
                label="Start Date"
                name="start_date"
                type="date"
                autoComplete="date"
                size="small"
                onChange={(e) => {
                  setStartDate(e.target.value);
                }}
                color="secondary"
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="end_date"
                label="End Date"
                name="end_date"
                type="date"
                autoComplete="date"
                size="small"
                onChange={(e) => {
                  setEndDate(e.target.value);
                }}
                color="secondary"
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="min_threshold"
                label="Min Criteria"
                name="min_threshold"
                autoComplete="text"
                size="small"
                onChange={(e) => {
                  setMinThreshold(e.target.value);
                }}
                color="secondary"
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="max_threshold"
                label="Max Criteria"
                name="max_threshold"
                autoComplete="text"
                size="small"
                onChange={(e) => {
                  setMaxThreshold(e.target.value);
                }}
                color="secondary"
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, fontWeight: "700" }}
                color="secondary"
                disabled={isUpdating}
              >
                {isUpdating ? <CircularProgress /> : "Add New Offer"}
              </Button>
            </Box>
          ) : (
            <>
              {" "}
              {
                <Box component="form" sx={{ mt: 1 }} onSubmit={update}>
                  <TextField
                    margin="normal"
                    fullWidth
                    id="code"
                    label="Code"
                    name="code"
                    autoFocus
                    value={code}
                    size="small"

                    color="secondary"
                    readOnly 
                  />
                  <Select
                    margin="dense"
                    fullWidth
                    color="primary"
                    size="big"
                    value={type}
                    readOnly 
                    onChange={(e) => {
                      setType(e.target.value);
                    }}
                  >
                    <InputLabel id="demo-select-big-label">
                      Offer Type
                    </InputLabel>
                    {OFFER_TYPE?.map((c) => (
                      <MenuItem value={c.value}>{c.name}</MenuItem>
                    ))}
                  </Select>
                  <Select
                    margin="dense"
                    fullWidth
                    color="secondary"
                    size="small"
                    value={discType}
                    readOnly 
                    onChange={(e) => {
                      setDiscType(e.target.value);
                    }}
                  >
                    <InputLabel id="demo-select-small-label">
                      Discount Type
                    </InputLabel>
                    {DISCOUNT_TYPE?.map((c) => (
                      <MenuItem value={c.value}>{c.name}</MenuItem>
                    ))}
                  </Select>
                  <TextField
                    margin="normal"
                    readOnly 
                    fullWidth
                    id="disc_value"
                    label="Discount Value"
                    name="disc_value"
                    value={discValue}
                    size="small"
                    color="secondary"
                  />
                  <TextField
                    margin="normal"
                    readOnly 
                    fullWidth
                    id="start_date"
                    label="Start Date"
                    name="start_date"
                    type="date"
                    value={startDate}
                    size="small"
                    color="secondary"
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="end_date"
                    label="End Date"
                    name="end_date"
                    type="date"
                    autoComplete="date"
                    value={endDate}
                    size="small"
                    onChange={(e) => {
                      setEndDate(e.target.value);
                    }}
                    color="secondary"
                  />
                  <TextField
                    margin="normal"
                    readOnly 
                    fullWidth
                    id="min_threshold"
                    label="Min Criteria"
                    name="min_threshold"
   
                    value={minThreshold}
                    size="small"
                    color="secondary"
                  />
                  <TextField
                    margin="normal"
                    readOnly 
                    fullWidth
                    id="max_threshold"
                    label="Max Criteria"
                    name="max_threshold"
  
                    value={maxThreshold}
                    size="small"
                    color="secondary"
                  />

                  <div
                    className="bttns"
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{ mt: 3, mb: 2, fontWeight: "700", width: "44%" }}
                      color="secondary"
                      disabled={isUpdating}
                    >
                      {isUpdating ? <CircularProgress /> : "Update"}
                    </Button>
                  </div>
                </Box>
              }
            </>
          )}
        </Box>
      </Modal>

      {/* Dailog */}
      <Dialog
        open={dailogOpne}
        onClose={handleDailogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      ></Dialog>
    </div>
  );
}

export default Offers;
