import React, { useMemo } from "react";
import { useState, useEffect } from "react";
import {
  Select,
  TextField,
  Typography,
  useTheme,
  MenuItem,
  Divider,
  Button,
  Modal,
  Backdrop,
  CircularProgress,
  Autocomplete,
  Switch,
  Snackbar,
  Alert,
} from "@mui/material";
import Box from "@mui/material/Box";

import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import moment from "moment/moment";
import { Stack } from "@mui/system";
import Skeleton from "@mui/material/Skeleton";
import { ADD, GET } from "../Functions/apiFunction";
import api from "../Data/api";
// import { useNavigate } from "react-router-dom";
import { tokens } from "../theme";
import { DateRangePicker } from "react-date-range";
import { addDays } from "date-fns";
import styled from "@emotion/styled";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs: "90vw",
    sm: "80vw",
    md: "60vw",
    lg: "600px",
    xl: "600px",
  },
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "8px",
  p: 2,
  textAlign: "center",
};


function Refunds() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [txns, setRefunds] = useState();
  const [mainproducts, setMainproducts] = useState();
  const [pageSize, setpageSize] = useState(20);
  const [reFetch, setreFetch] = useState(false);
  const [startDate, setstartDate] = useState();
  const [backdropOpen, setbackdropOpen] = useState(false);
  const [endDate, setendDate] = useState();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleBackDropOpen = () => setbackdropOpen(true);
  const handleBackDropClose = () => setbackdropOpen(false);
  const [isDateRange, setisDateRange] = useState(false);
  const [dateRange, setdateRange] = useState([
    {
      endDate: new Date(),
      startDate: addDays(new Date(), -7),
      key: "selection",
    },
  ]);


  const [snakbarOpen, setsnakbarOpen] = useState(false);
  const [alertType, setalertType] = useState("");
  const [alertMsg, setalertMsg] = useState("");
  const handleSnakBarOpen = () => setsnakbarOpen(true);
  const handleSnakBarClose = () => setsnakbarOpen(false);

  const admin = JSON.parse(sessionStorage.getItem("admin"));
  const token = `Bearer ${admin.token}`;
  useEffect(() => {
    // Get categoriues
    const getRefunds = async () => {
      const url = `${api}/refunds`;
      const refunds = await GET(token, url);
      setRefunds(refunds.data);
      setMainproducts(refunds.data);
    };

    getRefunds();

  }, [token, reFetch]);

  const filter = async (url) => {
    handleBackDropOpen();
    const refunds = await GET(token, url);
    handleBackDropClose();
    setRefunds(refunds.data);
    setMainproducts(refunds.data);
  };



  const column = useMemo(
    () => [
      { field: "id", headerName: "id", width: 50 },

      { field: "order_id", headerName: "Order Id", width: 90 },
      { field: "transaction_id", headerName: "Txn Id", width: 120 },
      { field: "reason", headerName: "Reason", width: 220 },
      { field: "razorpay_refund_id", headerName: "Rzpy RefundId", width: 200 },
      { field: "razorpay_payment_id", headerName: "Rzpy payId", width: 200 },
      { field: "amount", headerName: "Amount", width: 70 },
      { field: "status", headerName: "Status", width: 100 },
      { field: "created_by", headerName: "Created By", width: 120},
      { field: "created_at", headerName: "Created At", width: 170, 
      renderCell: (params) =>
      moment(params.row.updated_at).format("DD-MM-YYYY HH:MM:SS")},
      { field: "updated_at", headerName: "Updated At", width: 170 , 
      renderCell: (params) =>
      moment(params.row.updated_at).format("DD-MM-YYYY HH:MM:SS")},
      // {
      //   field: "Action",
      //   headerName: "Refresh",
      //   width: 80,
      //   renderCell: (params) => (
      //     <div style={{ display: "flex", alignItems: "center" }}>
      //        <button
      //           className="cssbuttons-io-button"
      //           onClick={(e) => {
      //             e.preventDefault();
      //             refreshRund(params);
      //           }}
      //           style={{ marginLeft: "6px" }}
      //         >
      //           Refund
      //           <div className="icon">
      //             <i className="fa-solid fa-hand-holding-dollar"></i>
      //           </div>
      //         </button>

           
      //     </div>
      //   ),
      // },

    ],
    []
  );

  // custom toolbar
  function CustomToolbar() {
    return (
      <GridToolbarContainer
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "20px",
          marginBottom: "20px",
        }}
      >
        <div>
          {" "}
          <GridToolbarExport color="secondary" sx={{ fontSize: "14px" }} />
          <Select
            sx={{
              width: "100px",
              height: "30px",
              marginLeft: "20px",
            }}
            color="primary"
            size="small"
            labelId="demo-select-small"
            id="demo-select-small"
            value={pageSize}
            label="Page Size"
            onChange={(e) => {
              setpageSize(e.target.value);
            }}
            className="TopPageBar"
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
        </div>
      </GridToolbarContainer>
    );
  }
  return (
    <div>
      <Snackbar
        open={snakbarOpen}
        autoHideDuration={3000}
        onClose={handleSnakBarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnakBarClose}
          severity={alertType}
          sx={{ width: "100%" }}
        >
          {alertMsg}
        </Alert>
      </Snackbar>
      <Box sx={{ height: 600, width: "100%" }}>
        <div>
          {" "}
          <Typography
            variant="h2"
            component={"h2"}
            fontWeight={500}
            sx={{ textAlign: "center", pb: "8px" }}
          >
            Refunds
          </Typography>
          <Divider />
          <Box mt={4} display={"flex"} alignItems={"center"} gap={"30px"}>
            <TextField
              margin="normal"
              size="small"
              sx={{
                width: { xs: "40%", sm: "300px", md: "400px" },
                mb: "15px",
              }}
              id="Search"
              label="Search"
              name="Search"
              color="secondary"
              onChange={(e) => {
                e.preventDefault();
                setTimeout(() => {
                  function searchArrayByValue(arr, searchQuery) {
                    return arr.filter((obj) => {
                      return Object.values(obj).some((val) => {
                        if (typeof val === "string") {
                          return val
                            .toLowerCase()
                            .includes(searchQuery.toLowerCase());
                        }
                        if (typeof val === "number") {
                          return val
                            .toString()
                            .toLowerCase()
                            .includes(searchQuery.toLowerCase());
                        }
                        return false;
                      });
                    });
                  }
                  setRefunds(
                    searchArrayByValue(
                      mainproducts,
                      e.target.value.toLowerCase()
                    )
                  );
                }, 500);
              }}
            />
            <TextField
              InputLabelProps={{ shrink: true }}
              id="outlined-basic"
              label="Select Date Range"
              variant="outlined"
              Autocomplete={false}
              size="small"
              color="secondary"
              onKeyDown={() => {
                return false;
              }}
              onClick={() => {
                handleOpen();
              }}
              value={startDate && `${startDate} - ${endDate}`}
            />
            <Button
              variant="contained"
              sx={{
                fontWeight: "700",
                color: "fff",
                width: "150px",
              }}
              color="secondary"
              disabled={!isDateRange}
              onClick={() => {
                let url = `${api}/refunds/by_date_range/${startDate}/${endDate}`;
                filter(url);
              }}
            >
              Submit
            </Button>
          </Box>
        </div>

        {txns ? (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              paddingBottom: "30px",
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-row": {
                fontSize: "14px",
              },
              "& .name-column--cell": {
                color: colors.greenAccent[300],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.navbarBG[400],
                borderBottom: "none",
                color: "#f5f5f5",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
                borderBottom: "#000",
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.navbarBG[400],
                color: "#f5f5f5 !important",
              },
              "& .MuiTablePagination-root": {
                color: "#f5f5f5 !important",
              },
              "& .MuiTablePagination-selectIcon": {
                color: "#f5f5f5 !important",
              },
              "& .MuiTablePagination-actions botton": {
                color: "#f5f5f5 !important",
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
            }}
          >
            {" "}
            <DataGrid
              sx={{ fontSize: "13px" }}
              columns={column}
              rows={txns}
              components={{ Toolbar: CustomToolbar }}
              rowsPerPageOptions={[10, 20, 25, 50, 100]}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setpageSize(newPageSize)}
            />
          </Box>
        ) : (
          <Stack spacing={1}>
            {/* For variant="text", adjust the height via font-size */}
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
            {/* For other variants, adjust the size with `width` and `height` */}

            <Skeleton
              variant="rectangular"
              animation="wave"
              width={"100%"}
              height={30}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              width={"100%"}
              height={30}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              width={"100%"}
              height={30}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              width={"100%"}
              height={30}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              width={"100%"}
              height={30}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              width={"100%"}
              height={30}
            />
          </Stack>
        )}
      </Box>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 5 }}
        open={backdropOpen}
        onClick={handleBackDropClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

export default Refunds;
