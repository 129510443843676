import React, { useMemo } from "react";
import { ADD, GET } from "../Functions/apiFunction";
import { useState, useEffect } from "react";
import {
  Autocomplete,
  CircularProgress,
  Select,
  MenuItem,
  Snackbar,
  Alert,
  Typography,
  Modal,
  TextField,
  Checkbox, FormControlLabel,
  useTheme,Tabs, Tab
} from "@mui/material";

import { useNavigate, useParams } from "react-router-dom";

import Box from "@mui/material/Box";

import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import moment from "moment/moment";
import { Stack } from "@mui/system";
import Skeleton from "@mui/material/Skeleton";
import api from "../Data/api";
import { useDispatch } from "react-redux";
import { tokens } from "../theme";

function Orders() {
  const navigate = useNavigate();
  const param = useParams();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [oneTimeOrders, setOneTimeOders] = useState();
  const [subscriptionOrders, setSubscriptionOrders] = useState();
  const [mainSubOrders, setMainSubOrders] = useState();
  const [mainproducts, setMainproducts] = useState();
  const [pageSize, setpageSize] = useState(20);
  
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const dispatch = useDispatch();

  const admin = JSON.parse(sessionStorage.getItem("admin"));
  const token = `Bearer ${admin.token}`;
  const status = (id) => {
    debugger
    const data = [
      {
        id: 1,
        text: "Confirmed",
      },
      {
        id: 2,
        text: "Cancelled",
      },
      {
        id: 0,
        text: "Pending",
      },
      {
        id: 3,
        text: "Complete refund initiated",
      },
      {
        id: 4,
        text: "Partial refund initiated",
      },
      {
        id: 5,
        text: "Complete refund processed",
      },
      {
        id: 6,
        text: "Partial refund processed",
      },
    ];
    const ttl = data.filter((dt) => dt.id === id);
    return ttl[0]?.text;
  };

  const refundTypes = [
    { name: "Full Refund", value: "Full Refund" },
    { name: "Partial Refund - SPOILED MILK", value: "Partial Refund - SPOILED MILK" },
    { name: "Partial Refund - PACKAGING ISSUE", value: "Partial Refund - PACKAGING ISSUE" },
    { name: "Partial Refund - QUALITY ISSUE", value: "Partial Refund - QUALITY ISSUE" },
    { name: "Partial Refund - UNDELIVERED", value: "Partial Refund - UNDELIVERED" },
    { name: "Bottle Amount Refund", value: "Bottle Amount Refund" },
    
  ];

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "90vw", sm: 500, md: 500, lg: 500, xl: 500 },
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "8px",
    p: 2,
    overflow: "scroll",
  };
  const [oneTimeOrderAmount, setOneTimeOrderAmount] = useState();
  const [oneTimeOrderId, setOneTimeOrderId] = useState();
  const [snakbarOpen, setsnakbarOpen] = useState(false);
  const [alertType, setalertType] = useState("info");
  const [alertMsg, setalertMsg] = useState("");
  const [reFetch, setreFetch] = useState(false);
  const [isUpdating, setisUpdating] = useState(false);
  const [selectedRefund, setselectedRefund] = useState();
  const [refundAmount, setRefundAmount] = useState(oneTimeOrderAmount);
  const [addRefundModal, setRefundModal] = useState(false);

  const [subscriptionOrderAmount, setSubscriptionOrderAmount] = useState();
  const [subscriptionOrderId, setSubscriptionOrderId] = useState();

  const [cancelOrder, setCancelOrder] = useState(false); // State to track cancel order checkbox
  const [toWallet, setToWallet] = useState(false); // State to track cancel order checkbox
  const [moneyDeducted, setMoneyDeducted] = useState(false); // State to track cancel order checkbox

  const handleCancelOrderChange = (event) => {
    setCancelOrder(event.target.checked);
  };

  const handleToWalletChange = (event) => {
    setToWallet(event.target.checked);
  };

  const handleMoneyDeductedChange = (event) => {
    setMoneyDeducted(event.target.checked);
  };

  const handleRefundDailogClose = () => {
    setRefundModal(false);
  };

  const handleOneTomeOrderRefundDailogOpen = (params) => {
    setOneTimeOrderAmount(params.row.order_amount);
    debugger;
    setRefundAmount(params.row.order_amount);
    setOneTimeOrderId(params.row.id);
    setRefundModal(true);
  };

  const handleSubsOrderRefundDailogOpen = (params) => {
    setSubscriptionOrderAmount(params.row.order_amount);
    debugger;
    setRefundAmount(params.row.subscription_balance_amount);
    setSubscriptionOrderId(params.row.id);
    setRefundModal(true);
  };
  const processRefundRequest = async (id,type, amount, refundAmount, cancelOrder, toWallet, orderType, moneyDeducted) => {
    if (amount < refundAmount || amount > 30000) {
      debugger;
      setisUpdating(false);
      handleSnakBarOpen();
      setalertType("error");
      setalertMsg(
        "Refund amount cant be more than total amount or threshold amount"
      );
    } else {
      const url = `${api}/refunds`;
      const data = {
        order_id: id,
        refund_amount: refundAmount,
        type: type,
        cancel_order: cancelOrder,
        order_type: orderType,
        to_wallet: toWallet,
        money_deducted: moneyDeducted
      };
      setisUpdating(true);
      const assignAdd = await ADD(token, url, data);
      debugger;
      if (assignAdd.response >= 200 && assignAdd.response < 300) {
        setisUpdating(false);
        handleRefundDailogClose();
        handleSnakBarOpen();
        setreFetch(!reFetch);
        setalertType("success");
        setalertMsg("Refunded successfully");
      } else {
        setisUpdating(false);
        handleSnakBarOpen();
        setalertType("error");
        setalertMsg("Something went Wrong! Please Try Again");
      }
    }
  };

  const handleSnakBarOpen = () => setsnakbarOpen(true);
  const handleSnakBarClose = () => setsnakbarOpen(false);

  useEffect(() => {
    // Get categoriues
    const getOneTimeOrders = async () => {
      const url = `${api}/get_order`;
      const oneTimeOrders = await GET(token, url);
      setOneTimeOders(oneTimeOrders.data);
      setMainproducts(oneTimeOrders.data);
    };

    const getSubscriptionOrders = async () => {
      const url = `${api}/get_subscribed_order`;
      const subscriptionOrders = await GET(token, url);
      debugger
      setSubscriptionOrders(subscriptionOrders.data);
      setMainSubOrders(subscriptionOrders.data);
    };
    getSubscriptionOrders();
    getOneTimeOrders();
  }, [token, dispatch]);



  const oneTimeOrderColumns = useMemo(
    () => [
      { field: "id", headerName: "id", width: 40 },
      { field: "trasation_id", headerName: "Trasation Id", width: 90 },
      { field: "title", headerName: "Product", width: 180 },
      {
        field: "order_type",
        headerName: "Order type",
        width: 100,
        renderCell: (params) => (
          <p>
            {params.row.order_type === 1
              ? "Prepaid"
              : params.row.order_type === 2
              ? "Postpaid"
              : params.row.order_type === 3
              ? "Pay Now"
              : params.row.order_type === 4
              ? "Pay Later"
              : params.row.subscription_type === null
              ? "N/A"
              : "N/A"}
          </p>
        ),
      },
      {
        field: "status",
        headerName: "Status",
        width: 80,
        renderCell: (params) => <p>{status(params.row.status)}</p>,
      },
      {
        field: "order_status",
        headerName: "Order Status",
        width: 80,
        renderCell: (params) =>
          params.row.subscription_type !== null ? (
            <p>{params.row.order_status === 0 ? "Active" : "Stopped"}</p>
          ) : (
            <p>{params.row.order_status === 0 ? "Active" : "N/A"}</p>
          ),
      },
      {
        field: "",
        headerName: "Subscription Type",
        width: 140,
        valueGetter: (params) => (
          
            params.row.subscription_type === 1
              ? "One Time Order"
              : params.row.subscription_type === 2
              ? "Weekly"
              : params.row.subscription_type === 3
              ? "Monthly"
              : params.row.subscription_type === 4
              ? "alternative days"
              : params.row.subscription_type === 100
              ? "Trial offer"
              : params.row.subscription_type === null
              ? "N/A"
              : "N/A"

        ),
        renderCell: (params) => (
          <p>
            {params.row.subscription_type === 1
              ? "One Time Order"
              : params.row.subscription_type === 2
              ? "Weekly"
              : params.row.subscription_type === 3
              ? "Monthly"
              : params.row.subscription_type === 4
              ? "alternative days"
              : params.row.subscription_type === 100
              ? "Trial offer"
              : params.row.subscription_type === null
              ? "N/A"
              : "N/A"}
          </p>
        ),
      },


      { field: "name", headerName: "Name", width: 180 },
      { field: "s_phone", headerName: "CustomerPhone", width: 110 },
      { field: "qty", headerName: "Qty", width: 40 },
      { field: "order_amount", headerName: "Amount", width: 80 },
      { field: "start_date", headerName: "Start Date", width: 80 },
      {
        field: "delivery_boy_name",
        headerName: "DeliveryBoy",
        width: 180,
      },
      // { field: "pincode", headerName: "Pincode", width: 70 },
      { field: "geolocation", headerName: "Geolocation", width: 160,
      valueGetter:(params) =>
      params.row.longitude !== null ? (
        `${params.row.latitude.toFixed(7)}, ${params.row.longitude.toFixed(7)}`

      ) : "N/A" ,
      renderCell: (params) =>
      params.row.longitude !== null ? (
        `${params.row.latitude.toFixed(7)}, ${params.row.longitude.toFixed(7)}`

      ) : "N/A" },
      { field: "address", headerName: "Address", width: 360,
      valueGetter:(params) =>
      `${params.row.flat_no ? params.row.flat_no + ', ' : ''}${params.row.apartment_name ? params.row.apartment_name + ', ' : ''}${params.row.landmark ? params.row.landmark + ', ' : ''}${params.row.area ? params.row.area + ', ' : ''}${params.row.city ? params.row.city + ', ' : ''}${params.row.pincode ? params.row.pincode : ''}`,      
      renderCell: (params) =>
`${params.row.flat_no ? params.row.flat_no + ', ' : ''}${params.row.apartment_name ? params.row.apartment_name + ', ' : ''}${params.row.landmark ? params.row.landmark + ', ' : ''}${params.row.area ? params.row.area + ', ' : ''}${params.row.city ? params.row.city + ', ' : ''}${params.row.pincode ? params.row.pincode : ''}`,
      },
      {
        field: "updated_at",
        headerName: "Last Update",
        width: 180,
        renderCell: (params) =>
          moment(params.row.updated_at).format("DD-MM-YYYY HH:MM:SS"),
      },
      {
        field: "Action",
        headerName: "Action",
        width: 260,
        renderCell: (params) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <button
              className="cssbuttons-io-button"
              onClick={() => {
                debugger;
                navigate(`/order/${params.row.id}partition${params.row.order_amount}`);
              }}
              style={{ marginRight: "6px" }}
            >
              <i className="fa-regular fa-pen-to-square"></i>
            </button>


              <button
                className="cssbuttons-io-button"
                onClick={(e) => {
                  e.preventDefault();
                  handleOneTomeOrderRefundDailogOpen(params);
                }}
                style={{ marginLeft: "6px" }}
              >
                Refund
                <div className="icon">
                  <i className="fa-solid fa-hand-holding-dollar"></i>
                </div>
              </button>

          </div>
        ),
      },
    ],
    [navigate]
  );

  const subscribedOrderColumns = useMemo(
    () => [
      { field: "id", headerName: "id", width: 40 },
      { field: "transaction_id", headerName: "Transaction Id", width: 90 },
      { field: "title", headerName: "Product", width: 180 },
     
      {
        field: "approval_status",
        headerName: "Approval Status",
        width: 80,
        renderCell: (params) => <p>{status(params.row.approval_status)}</p>,
      },
      {
        field: "status",
        headerName: "Order Status",
        width: 80,
        renderCell: (params) =>
          params.row.subscription_type !== null ? (
            <p>{params.row.order_status === 0 ? "Active" : "Stopped"}</p>
          ) : (
            <p>{params.row.order_status === 0 ? "Active" : "N/A"}</p>
          ),
      },
      {
        field: "",
        headerName: "Subscription Type",
        width: 140,
        valueGetter: (params) => (
          
            params.row.subscription_type === 2
              ? "Weekly"
              : params.row.subscription_type === 3
              ? "Monthly"
              : params.row.subscription_type === 4
              ? "alternative days"
              : params.row.subscription_type === 100
              ? "Trial offer"
              : params.row.subscription_type === null
              ? "N/A"
              : "N/A"

        ),
        renderCell: (params) => (
          <p>
            {params.row.subscription_type === 2
              ? "Weekly"
              : params.row.subscription_type === 3
              ? "Monthly"
              : params.row.subscription_type === 4
              ? "alternative days"
              : params.row.subscription_type === 100
              ? "Trial offer"
              : params.row.subscription_type === null
              ? "N/A"
              : "N/A"}
          </p>
        ),
      },


      { field: "name", headerName: "Name", width: 180 },
      { field: "s_phone", headerName: "CustomerPhone", width: 110 },
      { field: "qty", headerName: "Qty", width: 40 },
      { field: "order_amount", headerName: "Amount", width: 60 },
      { field: "subscription_balance_amount", headerName: "Balance Amt", width: 60 },
      { field: "offer_id", headerName: "Offer", width: 40 },
      { field: "start_date", headerName: "Start Date", width: 80 },
      { field: "end_date", headerName: "End Date", width: 80 },
      { field: "commencement_date", headerName: "Commencement Date", width: 80 },
      { field: "last_renewal_date", headerName: "Last Renewal Date", width: 80 },
      { field: "bottles", headerName: "Bottles", width: 40 },
      {
        field: "delivery_boy_name",
        headerName: "DeliveryBoy",
        width: 180,
      },
      // { field: "pincode", headerName: "Pincode", width: 70 },
      { field: "geolocation", headerName: "Geolocation", width: 160,
      valueGetter:(params) =>
      params.row.longitude !== null ? (
        `${params.row.latitude.toFixed(7)}, ${params.row.longitude.toFixed(7)}`

      ) : "N/A" ,
      renderCell: (params) =>
      params.row.longitude !== null ? (
        `${params.row.latitude.toFixed(7)}, ${params.row.longitude.toFixed(7)}`

      ) : "N/A" },
      { field: "address", headerName: "Address", width: 360,
      valueGetter:(params) =>
      `${params.row.flat_no ? params.row.flat_no + ', ' : ''}${params.row.apartment_name ? params.row.apartment_name + ', ' : ''}${params.row.landmark ? params.row.landmark + ', ' : ''}${params.row.area ? params.row.area + ', ' : ''}${params.row.city ? params.row.city + ', ' : ''}${params.row.pincode ? params.row.pincode : ''}`,      
      renderCell: (params) =>
`${params.row.flat_no ? params.row.flat_no + ', ' : ''}${params.row.apartment_name ? params.row.apartment_name + ', ' : ''}${params.row.landmark ? params.row.landmark + ', ' : ''}${params.row.area ? params.row.area + ', ' : ''}${params.row.city ? params.row.city + ', ' : ''}${params.row.pincode ? params.row.pincode : ''}`,
      },
      {
        field: "updated_at",
        headerName: "Last Update",
        width: 180,
        renderCell: (params) =>
          moment(params.row.updated_at).format("DD-MM-YYYY HH:MM:SS"),
      },
      {
        field: "Action",
        headerName: "Action",
        width: 260,
        renderCell: (params) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <button
              className="cssbuttons-io-button"
              onClick={() => {
                debugger;
                navigate(`/suborder/${params.row.id}partition${params.row.subscription_balance_amount}`);
              }}
              style={{ marginRight: "6px" }}
            >
              <i className="fa-regular fa-pen-to-square"></i>
            </button>

           
              <button
                className="cssbuttons-io-button"
                onClick={(e) => {
                  e.preventDefault();
                  handleSubsOrderRefundDailogOpen(params);
                }}
                style={{ marginLeft: "6px" }}
              >
                Refund
                <div className="icon">
                  <i className="fa-solid fa-hand-holding-dollar"></i>
                </div>
              </button>
            
          </div>
        ),
      },
    ],
    [navigate]
  );
  // custom toolbar
  function CustomToolbar() {
    return (
      <GridToolbarContainer
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "20px",
          marginBottom: "20px",
        }}
      >
        <div>
          {" "}
          <GridToolbarExport color="secondary" sx={{ fontSize: "14px" }} />
          <Select
            sx={{
              width: "100px",
              height: "30px",
              marginLeft: "20px",
            }}
            color="primary"
            size="small"
            labelId="demo-select-small"
            id="demo-select-small"
            value={pageSize}
            label="Page Size"
            onChange={(e) => {
              setpageSize(e.target.value);
            }}
            className="TopPageBar"
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
        </div>

        <button
          class="cssbuttons-io-button"
          onClick={() => {
            navigate("/neworder");
          }}
        >
          {" "}
          Add New
          <div class="icon">
            <i class="fa-regular fa-plus"></i>
          </div>
        </button>
      </GridToolbarContainer>
    );
  }
  // return (

  // );

  return(
<>
      <Tabs value={tabValue} onChange={handleTabChange} left>
        <Tab label="One time orders" />
        <Tab label="Subscription Orders" />
      </Tabs>
      <TabPanel value={tabValue} index={0}>
      <>
      <Snackbar
        open={snakbarOpen}
        autoHideDuration={3000}
        onClose={handleSnakBarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnakBarClose}
          severity={alertType}
          sx={{ width: "100%" }}
        >
          {alertMsg}
        </Alert>
      </Snackbar>
      <div>
        <Box sx={{ height: 600, width: "100%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "row-reverse",
            }}
          >
            {" "}
            <Typography
              variant="h3"
              component={"h3"}
              sx={{ textAlign: "left" }}
            >
              Manage Orders
            </Typography>
            <TextField
              margin="normal"
              size="small"
              sx={{ width: { xs: "80%", sm: "300px", md: "500px" } }}
              id="Search"
              label="Search"
              name="Search"
              color="secondary"
              onChange={(e) => {
                e.preventDefault();
                setTimeout(() => {
                  function searchArrayByValue(arr, searchQuery) {
                    return arr.filter((obj) => {
                      return Object.values(obj).some((val) => {
                        if (typeof val === "string") {
                          return val
                            .toLowerCase()
                            .includes(searchQuery.toLowerCase());
                        }
                        if (typeof val === "number") {
                          return val
                            .toString()
                            .toLowerCase()
                            .includes(searchQuery.toLowerCase());
                        }
                        return false;
                      });
                    });
                  }
                  setOneTimeOders(
                    searchArrayByValue(
                      mainproducts,
                      e.target.value.toLowerCase()
                    )
                  );
                }, 500);
              }}
            />
          </div>

          {oneTimeOrders ? (
            <Box
              sx={{
                width: "100%",
                height: "100%",
                paddingBottom: "30px",
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiDataGrid-cell": {
                  borderBottom: "none",
                },
                "& .MuiDataGrid-row": {
                  fontSize: "14px",
                },
                "& .name-column--cell": {
                  color: colors.greenAccent[300],
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: colors.navbarBG[400],
                  borderBottom: "none",
                  color: "#f5f5f5",
                },
                "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: colors.primary[400],
                  borderBottom: "#000",
                },
                "& .MuiDataGrid-footerContainer": {
                  borderTop: "none",
                  backgroundColor: colors.navbarBG[400],
                  color: "#f5f5f5 !important",
                },
                "& .MuiTablePagination-root": {
                  color: "#f5f5f5 !important",
                },
                "& .MuiTablePagination-selectIcon": {
                  color: "#f5f5f5 !important",
                },
                "& .MuiTablePagination-actions botton": {
                  color: "#f5f5f5 !important",
                },
                "& .MuiCheckbox-root": {
                  color: `${colors.greenAccent[200]} !important`,
                },
              }}
            >
              {" "}
              <DataGrid
                sx={{ fontSize: "12px" }}
                columns={oneTimeOrderColumns}
                rows={oneTimeOrders}
                components={{ Toolbar: CustomToolbar }}
                rowsPerPageOptions={[10, 20, 25, 50, 100]}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setpageSize(newPageSize)}
                getRowClassName={(params) => !params.row.delivery_boy_name? "inactive-row" : ""}
              />
            </Box>
          ) : (
            <Stack spacing={1}>
              {/* For variant="text", adjust the height via font-size */}
              <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
              {/* For other variants, adjust the size with `width` and `height` */}

              <Skeleton
                variant="rectangular"
                animation="wave"
                width={"100%"}
                height={30}
              />
              <Skeleton
                variant="rectangular"
                animation="wave"
                width={"100%"}
                height={30}
              />
              <Skeleton
                variant="rectangular"
                animation="wave"
                width={"100%"}
                height={30}
              />
              <Skeleton
                variant="rectangular"
                animation="wave"
                width={"100%"}
                height={30}
              />
              <Skeleton
                variant="rectangular"
                animation="wave"
                width={"100%"}
                height={30}
              />
              <Skeleton
                variant="rectangular"
                animation="wave"
                width={"100%"}
                height={30}
              />
            </Stack>
          )}
        </Box>
      </div>
      <Modal
      open={addRefundModal}
      onClose={handleRefundDailogClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h5" component="h2">
          Refund Request
        </Typography>
        <Box component="form" sx={{ mt: 3 }}>
       
          <Autocomplete
            disablePortal
            required
            fullWidth
            id="combo-box-demo"
            color="secondary"
            options={refundTypes}
            onChange={(e, data) => setselectedRefund(data.value)}
            getOptionLabel={(option) => `${option?.name}` || ""}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Refund type"
                size="small"
                fullWidth
                required
                color="secondary"
              />
            )}
          />
          <br />
          <TextField
             margin="normal"
             required
             fullWidth
             id="OrderAmount"
             label="Order Amount"
             type="number"
             color="secondary"
             autoComplete="number"
             size="small"
             value={refundAmount}
             InputProps={{
               inputProps: { min: 0, max: 100 },
             }}
             onChange={(e) => {
              const inputValue = e.target.value;
              if (!isNaN(inputValue)) {
                setRefundAmount(Number(inputValue));
              }
            }}
          />

        {/* Checkbox for Cancel Order */}
  <FormControlLabel
            control={<Checkbox checked={cancelOrder} onChange={handleCancelOrderChange} />}
            label="Cancel Order"
          />
            {/* Checkbox for Cancel Order */}
  <FormControlLabel
            control={<Checkbox checked={toWallet} onChange={handleToWalletChange} />}
            label="Refund To Wallet"
          />
            <FormControlLabel
            control={<Checkbox checked={moneyDeducted} onChange={handleMoneyDeductedChange} />}
            label="Money Already Deducted from wallet"
          />

          <button
            className="AddBtn"
            disabled={isUpdating}
            onClick={(e) => {
              e.preventDefault();
              if (!selectedRefund) {
                handleSnakBarOpen();
                setalertType("error");
                setalertMsg("Please Select Refund type");
              } else {
                processRefundRequest(
                  oneTimeOrderId,
                  selectedRefund,
                  oneTimeOrderAmount,
                  refundAmount,
                  cancelOrder,
                  toWallet,
                  "ONETIME",
                  moneyDeducted // Pass the cancelOrder state to your function
                );
              }
            }}
          >
            {isUpdating ? <CircularProgress color="inherit" /> : "Add"}
          </button>
        </Box>
      </Box>
    </Modal>
    </>
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
            <>
          <Snackbar
        open={snakbarOpen}
        autoHideDuration={3000}
        onClose={handleSnakBarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        {/* <Alert
          onClose={handleSnakBarClose}
          severity="info"
          sx={{ width: "100%" }}
        >
          {{alertMsg}}
        </Alert> */}
      </Snackbar>
      <div>
        <Box sx={{ height: 600, width: "100%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "row-reverse",
            }}
          >
            {" "}
            <Typography
              variant="h3"
              component={"h3"}
              sx={{ textAlign: "left" }}
            >
              Manage Orders
            </Typography>
            <TextField
              margin="normal"
              size="small"
              sx={{ width: { xs: "80%", sm: "300px", md: "500px" } }}
              id="Search"
              label="Search"
              name="Search"
              color="secondary"
              onChange={(e) => {
                e.preventDefault();
                setTimeout(() => {
                  function searchArrayByValue(arr, searchQuery) {
                    return arr.filter((obj) => {
                      return Object.values(obj).some((val) => {
                        if (typeof val === "string") {
                          return val
                            .toLowerCase()
                            .includes(searchQuery.toLowerCase());
                        }
                        if (typeof val === "number") {
                          return val
                            .toString()
                            .toLowerCase()
                            .includes(searchQuery.toLowerCase());
                        }
                        return false;
                      });
                    });
                  }
                  setOneTimeOders(
                    searchArrayByValue(
                      mainSubOrders,
                      e.target.value.toLowerCase()
                    )
                  );
                }, 500);
              }}
            />
          </div>

          {subscribedOrderColumns ? (
            <Box
              sx={{
                width: "100%",
                height: "100%",
                paddingBottom: "30px",
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiDataGrid-cell": {
                  borderBottom: "none",
                },
                "& .MuiDataGrid-row": {
                  fontSize: "14px",
                },
                "& .name-column--cell": {
                  color: colors.greenAccent[300],
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: colors.navbarBG[400],
                  borderBottom: "none",
                  color: "#f5f5f5",
                },
                "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: colors.primary[400],
                  borderBottom: "#000",
                },
                "& .MuiDataGrid-footerContainer": {
                  borderTop: "none",
                  backgroundColor: colors.navbarBG[400],
                  color: "#f5f5f5 !important",
                },
                "& .MuiTablePagination-root": {
                  color: "#f5f5f5 !important",
                },
                "& .MuiTablePagination-selectIcon": {
                  color: "#f5f5f5 !important",
                },
                "& .MuiTablePagination-actions botton": {
                  color: "#f5f5f5 !important",
                },
                "& .MuiCheckbox-root": {
                  color: `${colors.greenAccent[200]} !important`,
                },
              }}
            >
              {" "}
              <DataGrid
                sx={{ fontSize: "12px" }}
                columns={subscribedOrderColumns}
                rows={subscriptionOrders}
                components={{ Toolbar: CustomToolbar }}
                rowsPerPageOptions={[10, 20, 25, 50, 100]}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setpageSize(newPageSize)}
                getRowClassName={(params) => !params.row.delivery_boy_name? "inactive-row" : ""}
              />
            </Box>
          ) : (
            <Stack spacing={1}>
              {/* For variant="text", adjust the height via font-size */}
              <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
              {/* For other variants, adjust the size with `width` and `height` */}

              <Skeleton
                variant="rectangular"
                animation="wave"
                width={"100%"}
                height={30}
              />
              <Skeleton
                variant="rectangular"
                animation="wave"
                width={"100%"}
                height={30}
              />
              <Skeleton
                variant="rectangular"
                animation="wave"
                width={"100%"}
                height={30}
              />
              <Skeleton
                variant="rectangular"
                animation="wave"
                width={"100%"}
                height={30}
              />
              <Skeleton
                variant="rectangular"
                animation="wave"
                width={"100%"}
                height={30}
              />
              <Skeleton
                variant="rectangular"
                animation="wave"
                width={"100%"}
                height={30}
              />
            </Stack>
          )}
        </Box>
      </div>
      <Modal
        open={addRefundModal}
        onClose={handleRefundDailogClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h5" component="h2">
            Refund Request
          </Typography>
          <Box component="form" sx={{ mt: 3 }}>
            <Autocomplete
              disablePortal
              required
              fullWidth
              id="combo-box-demo"
              color="secondary"
              options={refundTypes}
              onChange={(e, data) => setselectedRefund(data.value)}
              getOptionLabel={(option) => `${option?.name}` || ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Refund type"
                  size="small"
                  fullWidth
                  required
                  color="secondary"
                />
              )}
            />
            <br />
            <TextField
               margin="normal"
               required
               fullWidth
               id="OrderAmount"
               label="Order Amount"
               type="number"
               color="secondary"
               autoComplete="number"
               size="small"
               defaultValue={subscriptionOrderAmount}
               value={refundAmount}  // Use the state value instead of defaultValue
               InputProps={{
                 inputProps: { min: 0, max: 100 },
               }}
               onChange={(e) => {
                const inputValue = e.target.value;
                if (!isNaN(inputValue)) {
                  setRefundAmount(Number(inputValue));
                }
              }}
            />

  {/* Checkbox for Cancel Order */}
  <FormControlLabel
            control={<Checkbox checked={cancelOrder} onChange={handleCancelOrderChange} />}
            label="Cancel Order"
          />
            {/* Checkbox for Cancel Order */}
  <FormControlLabel
            control={<Checkbox checked={toWallet} onChange={handleToWalletChange} />}
            label="Refund To Wallet"
          />
            <FormControlLabel
            control={<Checkbox checked={moneyDeducted} onChange={handleMoneyDeductedChange} />}
            label="Money Already Deducted from wallet"
          />
            <button
              className="AddBtn"
              disabled={isUpdating}
              onClick={(e) => {
                e.preventDefault();
                if (!selectedRefund) {
                  handleSnakBarOpen();
                  setalertType("error");
                  setalertMsg("Please Select Refund type");
                } else {
                  processRefundRequest(
                    subscriptionOrderId,
                    selectedRefund,
                    subscriptionOrderAmount,
                    refundAmount,
                    cancelOrder,
                  toWallet,
                  "SUBSCRIPTION",
                  moneyDeducted
                  );
                }
              }}
            >
              {isUpdating ? <CircularProgress color="inherit" /> : "Add"}
            </button>
          </Box>
        </Box>
      </Modal>
    </>
      </TabPanel>
    </>

  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export default Orders;
